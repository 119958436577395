import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Button } from '@shared/ui/buttons/Button';
import { ErrorList } from '@shared/ui/ErrorList';
import { TextField } from '@shared/ui/TextField';

const FIELD_CODE = 'code';

const initialFormValues = {
  [FIELD_CODE]: '',
};

const validationSchema = yup.object().shape({
  [FIELD_CODE]: yup.string().trim().required('errors.required'),
});

interface VerifyPasswordFormValues {
  code: string;
}

interface VerifyPhoneNumberProps {
  disabled?: boolean;

  errors?: string[];

  onSubmit: (code: string) => void;

  onBack: () => void;
}

export const VerifyPhoneNumber: React.FC<VerifyPhoneNumberProps> = (props) => {
  const { disabled, errors, onSubmit, onBack } = props;

  const { t } = useTranslation();
  const methods = useForm<VerifyPasswordFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialFormValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isValidating },
  } = methods;

  const onFormSubmit = handleSubmit(({ code }) => {
    onSubmit(code);
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={onFormSubmit} className="max-w-[528px] mx-auto">
        <div className="sign-up-form-card">
          <TextField id={FIELD_CODE} label="Code" />

          <ErrorList errors={errors || []} className="mt-2" />
        </div>

        <div className="flex flex-col items-center mt-8">
          <Button as="button" variant="primary" type="submit" disabled={isSubmitting || isValidating || disabled}>
            {t('continue')}
          </Button>

          <Button as="button" variant="secondary" type="button" className="mt-2" onClick={onBack}>
            {t('back')}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
