import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { ErrorList } from '@shared/ui/ErrorList';
import { Button } from '@shared/ui/buttons/Button';
import { PhoneInput, usePhoneInnerValidation } from '@shared/ui/PhoneInput/PhoneInput';

const FIELD_PHONE_NUMBER = 'phoneNumber';

const validationSchema = yup.object().shape({
  [FIELD_PHONE_NUMBER]: yup.string().trim().required('errors.required'),
});

const initialFormValues = {
  [FIELD_PHONE_NUMBER]: '',
};

interface SpecifyPhoneNumberFormProps {
  disabled?: boolean;

  errors?: string[];

  helperText?: string | React.ReactElement;

  onSubmit: (phoneNumber: string) => void;

  onBack: () => void;
}

export const SpecifyPhoneNumberForm: React.FC<SpecifyPhoneNumberFormProps> = (props) => {
  const { getPhoneInnerValidationResult, setPhoneInnerValidationResult } = usePhoneInnerValidation();
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialFormValues,
    context: {
      getPhoneInnerValidationResult,
    },
  });
  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { isSubmitting, isValidating, errors },
    control,
  } = methods;

  const onSubmit = handleSubmit(({ phoneNumber }) => {
    props.onSubmit(phoneNumber);
  });

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit} className="max-w-[528px] mx-auto">
          <div className="px-6 mt-8 md:mt-12 pt-10 pb-14 md:px-14 md:pb-16 rounded-lg border-2 border-hawkes-blue bg-white mt-0">
            <Controller
              name={FIELD_PHONE_NUMBER}
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <PhoneInput
                    autoFocus
                    value={value}
                    onChange={onChange}
                    label="Phone number"
                    helperText={props.helperText}
                    errorText={errors[FIELD_PHONE_NUMBER]?.message || props.errors?.[0]}
                    setPhoneInnerValidationResult={setPhoneInnerValidationResult}
                  />
                );
              }}
            />
          </div>

          <div className="flex flex-col items-center mt-8">
            <Button
              as="button"
              variant="primary"
              type="submit"
              disabled={isSubmitting || isValidating || props.disabled}
            >
              {t('continue')}
            </Button>

            <Button as="button" variant="secondary" type="button" className="mt-2" onClick={props.onBack}>
              {t('back')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
