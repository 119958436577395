import * as React from 'react';

import { MergeUsersTitle } from '@widgets/merge/title';
import { MergeUsersForm } from '@widgets/merge/merge-users-form';

const MergeUsersPage = () => {
  return (
    <div className="px-4 pt-6 pb-12 lg:pt-12">
      <MergeUsersTitle />

      <MergeUsersForm />
    </div>
  );
};

export default MergeUsersPage;
