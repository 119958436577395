import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SpecifyPhoneNumberForm } from '@widgets/auth/SpecifyPhoneNumberForm';
import { useLogIn } from '@hooks/useLogIn';
import { useNavigate } from '@hooks/useNavigate';
import { useCabinetNavigate } from '@shared/hooks/useCabinetNavigate';

import { VerifyPhoneNumber } from './verify-phone-number-form';

import { useCreateMergeUsersRequestMutation, useMergeUsersMutation } from '@store/api/user.api';
import { isApiError, translateApiError } from '@shared/utils/errors';
import { useUser } from '@shared/hooks/useUser';

import { LoaderWrapper } from '@shared/ui/loader/LoaderWrapper';
import { Loader } from '@shared/ui/loader/Loader';

import { useLogOut } from '@hooks/useLogOut';

export const MergeUsersForm = () => {
  const { t } = useTranslation();

  const logIn = useLogIn();
  const logOut = useLogOut();

  const [user] = useUser();

  const navigate = useNavigate();
  const cabinetNavigate = useCabinetNavigate();

  const [createMergeUsersRequest, { isLoading }] = useCreateMergeUsersRequestMutation();
  const [mergeUsers, { isLoading: isMergeUsersLoading }] = useMergeUsersMutation();

  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [apiErrors, setApiErrors] = useState<string[]>([]);

  const loading = isLoading || isMergeUsersLoading;

  const onPhoneNumberSubmit = async (phoneNumber: string) => {
    try {
      setApiErrors([]);

      await createMergeUsersRequest({ phoneNumber }).unwrap();

      setPhoneNumber(phoneNumber);
    } catch (err) {
      if (isApiError(err)) {
        setApiErrors(translateApiError(err, t));
      }
    }
  };

  const onPhoneNumberVerificationSubmit = async (code: string) => {
    try {
      setApiErrors([]);

      if (!phoneNumber) {
        return;
      }

      const { payload, token } = await mergeUsers({ phoneNumber, code }).unwrap();

      logIn(payload, token);
      cabinetNavigate(payload.type);
    } catch (err) {
      if (isApiError(err)) {
        setApiErrors(translateApiError(err, t));
      }
    }
  };

  if (!user || user.isGuest || user.phoneNumber) {
    navigate('/');

    return (
      <LoaderWrapper className="min-h-[200px]">
        <Loader />
      </LoaderWrapper>
    );
  }

  if (phoneNumber) {
    return (
      <VerifyPhoneNumber
        disabled={loading}
        errors={apiErrors}
        onSubmit={onPhoneNumberVerificationSubmit}
        onBack={() => setPhoneNumber(null)}
      />
    );
  }

  return (
    <SpecifyPhoneNumberForm
      disabled={loading}
      errors={apiErrors}
      onSubmit={onPhoneNumberSubmit}
      onBack={() => logOut()}
    />
  );
};
