import { useCallback } from 'react';

import { useNavigate } from '@hooks/useNavigate';
import { UserType } from '@shared/enums';

export function useCabinetNavigate() {
  const navigate = useNavigate();

  return useCallback(
    (userType: UserType) => {
      switch (userType || UserType.InNeed) {
        case UserType.Volunteer:
        case UserType.Guest:
          return navigate('/cabinet/volunteer');

        case UserType.InNeed:
          return navigate('/cabinet/customer');

        default:
          return navigate('/');
      }
    },
    [navigate],
  );
}
